import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

import { storage } from '../../firebase/firebase';
import ModalContainer from '../generales/ModalContainer';
import LoadingScreen from '../generales/LoadingScreen';
import { FormInputTextMultine } from '../forms/FormInputTextMultine';
import AddArchivo from '../forms/AddArchivo';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/user/userSlice';
import { guid } from '../../helpers/guid';
import SelectComponent from '../forms/SelectComponent';

export default function LicitacionesDetailMenuCQaModal({
  open,
  onClose,
  licitacion,
  forceReload,
  categorySelected,
}) {
  const intl = useIntl();
  const userId = useSelector(selectUser).userId;

  const [loading, setLoading] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [category, setCategory] = useState(categorySelected);

  const defaultValues = {
    question: '',
  };

  const methods = useForm({ defaultValues: defaultValues, mode: 'onChange' });
  const { handleSubmit, reset, control, setValue, formState, watch } = methods;

  const uploadStorageFile = async (file) => {
    try {
      const idFile = guid();
      const storageRef = ref(storage, `licitaciones_qa_files/${userId}/${idFile}`);
      await uploadBytesResumable(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      return {
        idFile,
        name: file.name,
        size: file.size,
        metadata: file.type,
        downloadURL,
        userId,
        path: `licitaciones_qa_files/${userId}/${idFile}`,
      };
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  const submitHandler = async (data) => {
    setLoading(true);
    try {
      // subo los archivos a storage
      let promises = [];
      archivos.forEach((x) => {
        promises.push(uploadStorageFile(x));
      });
      const filePaths = await Promise.all(promises);
      // llamo a la función con los path de los archivos
      // en la funcion, hago la gestión de los archivos y luego los elimino
      const functions = getFunctions();
      const licitaciones_question_oncall = httpsCallable(functions, 'licitaciones_question_oncall');
      await licitaciones_question_oncall({
        idProject: licitacion.idProject,
        idLicitacion: licitacion.idLicitacion,
        question: data.question,
        category,
        filePaths,
      });
      setTimeout(() => {
        forceReload();
        onClose();
      }, 1000);
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
    }
  };

  const isValid = () => {
    if (!formState.isValid) return false;
    return true;
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={intl.formatMessage({ id: 'app.licitaciones.gridC', defaultMessage: '' })}
      maxWidth={'600px'}
      buttonsArray={[
        {
          name: intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' }),
          isValid: isValid(),
          onClick: isValid() ? handleSubmit(submitHandler) : null,
        },
      ]}
    >
      {loading ? (
        <LoadingScreen />
      ) : (
        <ContentContainer>
          <InputContainer>
            <Title>
              <FormattedMessage id="app.category" />
            </Title>
            <Spacer height={'10px'} />
            <SelectComponent
              value={category}
              changeHandle={(e) => setCategory(e.target.value)}
              items={licitacion.qaCategories || []}
              disabled={false}
              marginBottom={'0px'}
              inputLabel={''}
            />
          </InputContainer>

          <InputContainer>
            <Title>
              <FormattedMessage id="app.question" />
            </Title>
            <Spacer height={'10px'} />
            <FormInputTextMultine
              name={'question'}
              control={control}
              label={intl.formatMessage({
                id: 'app.writehere',
                defaultMessage: '',
              })}
              required={true}
              disabled={false}
              rows={5}
            />
          </InputContainer>
          {/* <InputContainer>
            <Title>
              <FormattedMessage id="app.attachedfiles" />
            </Title>
            <AddArchivo archivos={archivos} setArchivos={(x) => setArchivos(x)} maxArchivos={10} />
          </InputContainer> */}
        </ContentContainer>
      )}
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;
