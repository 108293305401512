import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Avatar from '@mui/material/Avatar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import PageContent from '../generales/PageContent';
import { selectUser, setSignOutState } from '../../features/user/userSlice';
import { db } from '../../firebase/firebase';
import ModalAlert from '../generales/ModalAlert';
import LoadingScreen from '../generales/LoadingScreen';
import { firestoreDate } from '../../helpers/firestoreDate';
import { orderBy } from '../../helpers/orderBy';
import { convertTZ } from '../../helpers/convertTZ';
import { meses, mesesEn } from '../../data/time';

export default function Perfil() {
  const user = useSelector(selectUser);
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sesionModal, setSesionModal] = useState(false);
  const [loadingAccesos, setLoadingAccesos] = useState(true);
  const [accesos, setAccesos] = useState([]);

  const one = new Date();
  const two = new Date();
  two.setMonth(two.getMonth() - 1);
  const three = new Date();
  three.setMonth(three.getMonth() - 2);
  let countOne = 0;
  let countTwo = 0;
  let countThree = 0;
  accesos.forEach((x) => {
    const date = convertTZ(new Date(x.createdAt.seconds * 1000), 'America/Santiago');
    if (one.getMonth() === date.getMonth() && one.getFullYear() === date.getFullYear()) {
      countOne++;
    }
    if (two.getMonth() === date.getMonth() && two.getFullYear() === date.getFullYear()) {
      countTwo++;
    }
    if (three.getMonth() === date.getMonth() && three.getFullYear() === date.getFullYear()) {
      countThree++;
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const querySnapshot = await getDocs(collection(db, `users/${user.userId}/login_analytics`));
        const docs = querySnapshot.docs.map((x) => {
          return { idAcceso: x.id, ...x.data() };
        });
        setAccesos(docs);
        setLoadingAccesos(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const submitL10n = async (val) => {
    try {
      await updateDoc(doc(db, `users/${user.userId}`), {
        locale: val,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAuth = () => {
    setSesionModal(true);
    setTimeout(() => {
      setSesionModal(false);
      navigate('/');
      getAuth()
        .signOut()
        .then(() => {
          dispatch(setSignOutState());
        })
        .catch((err) => alert(err.message));
    }, 1500);
  };

  const Info = ({ title, text }) => (
    <InfoBox>
      <span>{title.toUpperCase()}</span>
      <span>{text}</span>
    </InfoBox>
  );

  const Pref2 = ({ title, subTitle, value, id }) => (
    <PrefBox>
      <div>
        <span>{title}</span>
        <span>{subTitle}</span>
      </div>
      <CheckBoxWrapper>
        <CheckBox
          id={`checkbox${id}`}
          type="checkbox"
          checked={value}
          onChange={() => submitL10n(id)}
        />
        <CheckBoxLabel htmlFor={`checkbox${id}`} />
      </CheckBoxWrapper>
    </PrefBox>
  );

  return (
    <PageContent title={'MI CUENTA'} backHandler={() => navigate(-1)}>
      <ContentContainer>
        <div>
          <div>
            <SectionContainer>
              <div>
                <span>Profile</span>
              </div>
              <ProfileContainer>
                <div>
                  <AvatarLogo>{user.name.substring(0, 2).toUpperCase()}</AvatarLogo>
                </div>
                <div>
                  <Info
                    title={intl.formatMessage({ id: 'app.name', defaultMessage: '' })}
                    text={user.name}
                  />
                  <Info
                    title={intl.formatMessage({ id: 'app.email', defaultMessage: '' })}
                    text={user.email}
                  />
                  <Info
                    title={intl.formatMessage({ id: 'app.cel', defaultMessage: '' })}
                    text={user.celular || '-'}
                  />
                </div>
              </ProfileContainer>
              <EditButton>
                <span>
                  <FormattedMessage id="app.edit" defaultMessage="" />
                </span>
              </EditButton>
            </SectionContainer>
            <SectionContainer>
              <div>
                <span>Notificaciones</span>
              </div>
              <NotificationContainer>
                <span>No se han definido preferencias</span>
              </NotificationContainer>
            </SectionContainer>
            <SectionContainer>
              <div>
                <span>Accesos</span>
              </div>
              {loadingAccesos ? (
                <LoadingScreen />
              ) : (
                <AccesosContainer>
                  <span>Se muestran los ultimos 10 registros</span>
                  <div>
                    <span>Fecha</span>
                    <span>Hora</span>
                  </div>
                  {orderBy(accesos, 'createdAt')
                    .reverse()
                    .slice(0, 10)
                    .map((x) => (
                      <div key={x.idAcceso}>
                        <span>{firestoreDate(x.createdAt, false, intl.locale)}</span>
                        <span>{firestoreDate(x.createdAt, true, intl.locale).split(' ')[1]}</span>
                      </div>
                    ))}
                  <Spacer height={'20px'} />
                  <span>Últimos 3 meses</span>
                  <div>
                    <span>{(intl.locale === 'es' ? meses : mesesEn)[one.getMonth()]}</span>
                    <span>{countOne}</span>
                  </div>
                  <div>
                    <span>{(intl.locale === 'es' ? meses : mesesEn)[two.getMonth()]}</span>
                    <span>{countTwo}</span>
                  </div>
                  <div>
                    <span>{(intl.locale === 'es' ? meses : mesesEn)[three.getMonth()]}</span>
                    <span>{countThree}</span>
                  </div>
                </AccesosContainer>
              )}
            </SectionContainer>
          </div>
          <div>
            <SectionContainer>
              <div>
                <span>
                  <FormattedMessage id="app.login.language" defaultMessage="" />
                </span>
              </div>
              <ConfigContainer>
                <Pref2
                  title={intl.formatMessage({ id: 'app.spanish', defaultMessage: '' })}
                  subTitle={''}
                  value={user.locale === 'es' || false}
                  id={'es'}
                />
                <Pref2
                  title={intl.formatMessage({ id: 'app.english', defaultMessage: '' })}
                  subTitle={''}
                  value={user.locale === 'en' || false}
                  id={'en'}
                />
              </ConfigContainer>
            </SectionContainer>
            <SectionContainer>
              <div>
                <span>
                  <FormattedMessage id="app.session" defaultMessage="" />
                </span>
              </div>
              <SesionContainer onClick={handleAuth}>
                <span>
                  <FormattedMessage id="app.logout" defaultMessage="" />
                </span>
                <ExitToAppIcon />
              </SesionContainer>
            </SectionContainer>
          </div>
        </div>
      </ContentContainer>
      {sesionModal && (
        <ModalAlert
          open={sesionModal}
          onClose={() => setSesionModal(false)}
          text={intl.formatMessage({ id: 'app.logingout', defaultMessage: '' })}
        />
      )}
    </PageContent>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  max-width: 1024px;
  margin-top: 50px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-column-gap: 35px;
    padding: 25px;

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: white;
  margin-bottom: 35px;

  > div:nth-child(1) {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    > span {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      color: ${(props) => props.theme.orangeColor};
    }
  }
`;

const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  width: 100%;
`;

const NotificationContainer = styled.div`
  display: flex;

  > span {
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
  }
`;

const AccesosContainer = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    > span {
      font-size: 14px;
      font-weight: 300;
    }
  }

  > div:nth-child(2) {
    border-bottom: 1px solid whitesmoke;
    > span {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const AvatarLogo = styled(Avatar)`
  width: 100px !important;
  height: 100px !important;
  font-size: 45px !important;
  font-weight: 300;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  > span:first-child {
    font-size: 10px;
    font-weight: 600;
  }

  > span:last-child {
    font-size: 14px;
    font-weight: 300;
  }
`;

const PrefBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > span:first-child {
      font-size: 14px;
      font-weight: 300;
    }

    > span:last-child {
      font-size: 12px;
      font-weight: 300;
    }
  }

  > div:last-child {
  }
`;

const ConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  margin-left: 10px;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: ${(props) => props.theme.cincoColor};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const SesionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;

  > span {
    font-size: 14px;
    font-weight: 300;
    transition: 0.3s all ease-in-out;
  }
  > .MuiSvgIcon-root {
    color: grey;
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    transition: 0.3s all ease-in-out;
    background-color: #ffaa33;
    > span {
      color: white;
    }
    > .MuiSvgIcon-root {
      color: white;
    }
  }
`;

const EditButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2px 10px;
  background-color: whitesmoke;
  border-radius: 4px;
  > span {
    font-size: 14px;
    font-weight: 300;
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    transition: 0.3s all ease-in-out;
    background-color: ${(props) => props.theme.cincoColor};
    > span {
      color: white;
    }
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;
