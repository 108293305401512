import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useWindowSize } from 'react-use';
import * as Sentry from '@sentry/react';
import { FormattedMessage, useIntl } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { db } from '../../firebase/firebase';
import { emailValidator } from '../../helpers/emailValidator';
import { setUserLoginDetails } from '../../features/user/userSlice';
import { useDeviceDetect } from '../../helpers/useDeviceDetect';
import FallbackComponent from '../generales/FallbackComponent';
import ModalResetPassword from './ModalResetPassword';
import IntlSelector from './IntlSelector';
import { SignUpModal } from './SignUpModal';

import logoBlanco2 from '../../images/login/LogoBlanco2.png';
import logoAzul from '../../images/login/LogoAzul.png';
import { logAnalytics } from '../../helpers/logAnalytics';

export default function Login({ locale, setLocale }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const auth = getAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWelcome, setIsLoadingWelcome] = useState(true);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [signin, setSignin] = useState(true);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [errorSignUp, setErrorSignUp] = useState(false);
  const [loadingSignUp, setLoadingSignUp] = useState(false);

  const { isMobile } = useDeviceDetect();
  const { width, height } = useWindowSize();

  const intl = useIntl();

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, async (userAuthFirebase) => {
      if (userAuthFirebase !== null && userAuthFirebase.emailVerified) {
        if (!isLoading) {
          try {
            const docSnapshot = await getDoc(doc(db, `users/${userAuthFirebase.uid}`));
            if (docSnapshot.exists) {
              const userInfo = { userId: userAuthFirebase.uid, ...docSnapshot.data() };
              await logAnalytics({
                user: userInfo,
                path: `users/${userAuthFirebase.uid}/login_analytics`,
                type: 'login',
              });
              dispatch(setUserLoginDetails(userInfo));
              navigate(`${pathname}${location.search}`);
            } else {
              setIsLoadingWelcome(false);
              throw new Error(intl.formatMessage({ id: 'app.login.alertA', defaultMessage: '' }));
            }
          } catch (err) {
            console.log(err, 'useEffect');
            setIsLoadingWelcome(false);
          }
        }
      } else if (userAuthFirebase !== null && !userAuthFirebase.emailVerified) {
        setIsLoadingWelcome(false);
        alert(intl.formatMessage({ id: 'app.login.alertB', defaultMessage: '' }));
      } else {
        setIsLoadingWelcome(false);
      }
    });

    return () => subscriber();
  }, []);

  const signInHandler = async () => {
    setIsLoading(true);
    try {
      if (!email) {
        throw new Error(intl.formatMessage({ id: 'app.login.errorA', defaultMessage: '' }));
      }
      if (!password) {
        throw new Error(intl.formatMessage({ id: 'app.login.errorB', defaultMessage: '' }));
      }
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (!response) {
        throw new Error(intl.formatMessage({ id: 'app.login.errorC', defaultMessage: '' }));
      }
      if (!response.user.emailVerified) {
        throw new Error(intl.formatMessage({ id: 'app.login.alertB', defaultMessage: '' }));
      }
      const docSnapshot = await getDoc(doc(db, `users/${response.user.uid}`));
      if (!docSnapshot.exists) {
        throw new Error(intl.formatMessage({ id: 'app.login.errorE', defaultMessage: '' }));
      }
      const userInfo = { userId: response.user.uid, ...docSnapshot.data() };
      await logAnalytics({
        user: userInfo,
        path: `users/${response.user.uid}/login_analytics`,
        type: 'login',
      });
      dispatch(setUserLoginDetails(userInfo));
    } catch (err) {
      console.log(err, 'login');
      Sentry.captureException(err, (scope) => {
        scope.setTag('email', email || 'no email defined');
        scope.setTag('err.code', err.code);
      });
      Sentry.captureMessage(`Error Code:${err.code}. Email: ${email || 'no emial defined'}`, 'log');
      setIsLoading(false);
      if (err.code === 'auth/wrong-password' || err.code === 'auth/user-not-found') {
        alert(intl.formatMessage({ id: 'app.login.alertC', defaultMessage: '' }));
      } else if (err.code === 'auth/network-request-failed') {
        alert(intl.formatMessage({ id: 'app.login.alertD', defaultMessage: '' }));
      } else {
        alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
      }
    }
  };

  const signUpHandler = async () => {
    if (!emailValidator(email)) {
      setErrorEmail(true);
    }
    if (password !== confirmPassword) {
      setErrorPassword(true);
    }
    if (!emailValidator(email) || password !== confirmPassword) {
      alert(intl.formatMessage({ id: 'app.alerterrorB' }));
      return;
    }

    setErrorSignUp(false);
    setLoadingSignUp(true);
    setSignUpModal({ open: true });

    try {
      const functions = getFunctions();
      const signup_oncall = httpsCallable(functions, 'signup_oncall');
      await signup_oncall({ name, email, password });
      setSignin(true);
      setName('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setErrorEmail(false);
      setErrorPassword(false);
      setLoadingSignUp(false);
    } catch (err) {
      console.log('error');
      setLoadingSignUp(false);
      setErrorSignUp(true);
    }
  };

  return (
    <Sentry.ErrorBoundary
      fallback={<FallbackComponent />}
      showDialog
      beforeCapture={(scope) => {
        scope.setTag('name', 'no esta logeado');
        scope.setTag('email', 'no esta logeado');
        scope.setTag('role', 'no esta logeado');
      }}
    >
      <Container height={height} ismobile={isMobile}>
        <Content height={height} ismobile={isMobile}>
          <FormContainer height={height} ismobile={isMobile}>
            <LogoContainer>
              {isMobile || width <= 768 ? <Logo src={logoBlanco2} /> : <Logo src={logoAzul} />}
            </LogoContainer>
            {isLoadingWelcome ? (
              <WelcomeContainer height={height} ismobile={isMobile}>
                <CircularProgress />
                <span>
                  <FormattedMessage id="app.login.loading" defaultMessage={''} />
                </span>
              </WelcomeContainer>
            ) : (
              <>
                <Form>
                  {signin && (
                    <FormText>
                      <h3>
                        <span>
                          <FormattedMessage id="app.login.title" defaultMessage={'Welcome'} />
                        </span>
                      </h3>
                      <span>
                        <FormattedMessage
                          id="app.login.subtitle"
                          defaultMessage={'Enter your details below'}
                        />
                      </span>
                    </FormText>
                  )}
                  {!signin && (
                    <FormText>
                      <h3>
                        <span>
                          <FormattedMessage
                            id="app.login.account"
                            defaultMessage={'Enter your details below'}
                          />
                        </span>
                      </h3>
                      <span>
                        <FormattedMessage
                          id="app.login.subtitle"
                          defaultMessage={'Enter your details below'}
                        />
                      </span>
                    </FormText>
                  )}
                  {!signin && (
                    <span>
                      <FormattedMessage id="app.name" />
                    </span>
                  )}
                  {!signin && (
                    <input
                      id="name"
                      type="text"
                      value={name}
                      placeholder={intl.formatMessage({
                        id: 'app.name',
                        defaultMessage: 'Name',
                      })}
                      onChange={(e) => setName(e.target.value)}
                    />
                  )}
                  {!signin && <Spacer height={'25px'} />}
                  <span>Email</span>
                  <input
                    id="email"
                    type="text"
                    value={email}
                    placeholder={intl.formatMessage({
                      id: 'app.login.placeholder',
                      defaultMessage: 'name@company.com',
                    })}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errorEmail && !emailValidator(email) && (
                    <ErrorContainer>
                      <span>
                        <FormattedMessage
                          id="app.login.errorEmail"
                          defaultMessage={'Must be an email'}
                        />
                      </span>
                    </ErrorContainer>
                  )}
                  <Spacer height={'25px'} />
                  <span>
                    <FormattedMessage id="app.login.password" defaultMessage={'Password'} />
                    {!signin &&
                      ` (${intl.formatMessage({
                        id: 'app.login.minPassword',
                        defaultMessage: 'Minimum 6 characters',
                      })})`}
                  </span>
                  <PasswordContainer showpassword={showPassword}>
                    <input
                      id="pass"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={'**********'}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    {showPassword && <VisibilityIcon onClick={() => setShowPassword(false)} />}
                    {!showPassword && <VisibilityOffIcon onClick={() => setShowPassword(true)} />}
                  </PasswordContainer>
                  {!signin && <Spacer height={'10px'} />}
                  {!signin && (
                    <PasswordContainer showpassword={showPassword}>
                      <input
                        id="pass2"
                        type={showPassword ? 'text' : 'password'}
                        placeholder={'**********'}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                      />
                      {showPassword && <VisibilityIcon onClick={() => setShowPassword(false)} />}
                      {!showPassword && <VisibilityOffIcon onClick={() => setShowPassword(true)} />}
                    </PasswordContainer>
                  )}
                  {!signin && errorPassword && password !== confirmPassword && (
                    <ErrorContainer>
                      <span>
                        <FormattedMessage
                          id="app.login.errorPassword"
                          defaultMessage={'Passwords do not match'}
                        />
                      </span>
                    </ErrorContainer>
                  )}
                  <Spacer height={'25px'} />
                  {signin && (
                    <SignIn onClick={signInHandler} id="submit">
                      {isLoading ? (
                        <div>
                          <CircularProgress
                            color="inherit"
                            size="15px"
                            style={{ marginRight: 10, padding: 0 }}
                          />
                          <span>
                            {' '}
                            <FormattedMessage
                              id="app.login.loading"
                              defaultMessage={'Loading ...'}
                            />
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span>
                            <FormattedMessage id="app.login.signin" defaultMessage={'Sign in'} />
                          </span>
                        </div>
                      )}
                    </SignIn>
                  )}
                  <SignUp
                    onClick={() => {
                      if (signin) {
                        setSignin(false);
                      } else {
                        signUpHandler();
                      }
                    }}
                    id="signup"
                    signin={signin}
                    isvalid={
                      name.length > 0 &&
                      email.length > 0 &&
                      password.length >= 6 &&
                      confirmPassword.length >= 6
                    }
                  >
                    <div>
                      <span>
                        {signin && (
                          <FormattedMessage id="app.login.signupText" defaultMessage={'Sign in'} />
                        )}
                        {signin && '? '}
                        <FormattedMessage id="app.login.signup" defaultMessage={'Sign in'} />
                      </span>
                    </div>
                  </SignUp>
                  {!signin && (
                    <GoBack
                      onClick={() => {
                        setSignin(true);
                        setName('');
                        setEmail('');
                        setPassword('');
                        setConfirmPassword('');
                        setErrorEmail(false);
                        setErrorPassword(false);
                      }}
                    >
                      {isLoading ? (
                        <div>
                          <CircularProgress
                            color="inherit"
                            size="15px"
                            style={{ marginRight: 10, padding: 0 }}
                          />
                          <span>
                            {' '}
                            <FormattedMessage
                              id="app.login.loading"
                              defaultMessage={'Loading ...'}
                            />
                          </span>
                        </div>
                      ) : (
                        <div>
                          <ArrowBackIcon />
                          <span>
                            Volver a{' '}
                            <FormattedMessage id="app.login.signin" defaultMessage={'Sign in'} />
                          </span>
                        </div>
                      )}
                    </GoBack>
                  )}
                </Form>
                {signin && (
                  <ResetPassword onClick={() => setOpenResetPasswordModal(true)}>
                    <span>
                      <FormattedMessage id="app.login.reset" defaultMessage={'Reset password'} />
                    </span>
                  </ResetPassword>
                )}
                <FooterContent>
                  <span>
                    <FormattedMessage
                      id="app.login.accessproblem"
                      defaultMessage={'Problems accesing?'}
                    />
                  </span>
                  <span id={'support'}>
                    <FormattedMessage id="app.login.write" defaultMessage={'Write to us'} />
                  </span>
                </FooterContent>
              </>
            )}
            <IntlSelector locale={locale} setLocale={setLocale} />
            {openResetPasswordModal && (
              <ModalResetPassword
                open={openResetPasswordModal}
                onClose={() => setOpenResetPasswordModal(false)}
                email={email}
                auth={auth}
              />
            )}
          </FormContainer>
        </Content>
        {signUpModal.open && (
          <SignUpModal
            open={signUpModal.open}
            onClose={() => setSignUpModal(false)}
            loading={loadingSignUp}
            error={errorSignUp}
          />
        )}
      </Container>
    </Sentry.ErrorBoundary>
  );
}

const Container = styled.section`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: ${(props) => props.theme.primaryColor};
  overflow-y: scroll;

  @media (max-width: 768px) {
    height: ${(props) => (props.ismobile ? `${props.height}px` : '100vh')};
    padding: 0px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-color: ${(props) => props.theme.primaryColor};

    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-height: 800px) {
    justify-content: flex-start;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  max-height: 800px;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07),
    0 32px 64px rgba(0, 0, 0, 0.07);

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: ${(props) => (props.ismobile ? `${props.height}px` : '100vh')};
    max-height: none;
    border-radius: 0px;
    background-color: ${(props) => props.theme.primaryColor};
    box-shadow: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  /* background-color: red; */

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  min-height: 400px;

  > .MuiCircularProgress-root {
    color: grey;

    @media (max-width: 768px) {
      color: white;
    }
  }

  > span {
    color: ${(props) => (props.theme.name === 'light' ? 'grey' : 'white')};
    font-size: 14px;
    margin: 20px;

    @media (max-width: 768px) {
      color: white;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: ${(props) => (props.ismobile ? `${props.height}px` : '100vh')};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0 0 0 20px;
  /* background-color: ${(props) => props.theme.primaryColor}; */
`;

const Logo = styled.img`
  max-width: 120px;
  display: block;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 70%;

  > span {
    color: ${(props) => (props.theme.name === 'light' ? 'grey' : 'white')};
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      color: white;
    }
  }

  > input {
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    border: 1px solid lightgray;
    border-radius: 4px;
    font-size: 15px;
    background-color: white;
    color: dimgray;
    font-weight: 300;

    &::placeholder {
      font-size: 14px;
      color: lightgrey;
      font-style: italic;
      font-weight: 300;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12-18 */
      font-size: 14px;
      color: lightgrey;
      font-style: italic;
      font-weight: 300;
    }
  }

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const FormText = styled.div`
  margin: 40px 0;
  color: ${(props) => (props.theme.name === 'light' ? 'grey' : 'white')};
  text-align: center;

  > h3 {
    padding: 0;
    margin: 0;
    font-size: 25px;
    font-weight: 500;
    color: dimgray;

    @media (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 10px;
      color: white;
    }
  }

  > span {
    font-size: 14px;

    @media (max-width: 768px) {
      color: white;
    }
  }

  @media (max-width: 768px) {
    margin-top: 80px;
  }
`;

const SignIn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${(props) => props.theme.cincoColor};
    border-radius: 4px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    padding: 12px 0;

    > span {
      font-size: 14px;
      color: white;
    }

    &:hover {
      background-color: ${(props) => props.theme.primaryColor};
    }
  }
`;

const SignUp = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${(props) =>
      props.signin || (!props.signin && props.isvalid) ? props.theme.orangeColor : 'lightgrey'};
    border-radius: 4px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    padding: 12px 0;

    > span {
      font-size: 14px;
      color: white;
    }

    &:hover {
      background-color: ${(props) =>
        props.signin
          ? props.theme.orangeColor
          : !props.signin && props.isvalid
          ? '#03C03C'
          : 'lightgrey'};
    }
  }
`;

const GoBack = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0 80px 0;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${(props) => props.theme.cincoColor};
    border-radius: 4px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    padding: 12px 0;

    > .MuiSvgIcon-root {
      font-size: 15px;
      margin-right: 5px;
      color: white;
    }

    > span {
      font-size: 14px;
      color: white;
    }

    &:hover {
      background-color: ${(props) => props.theme.primaryColor};
    }
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 20px 20px;
  cursor: pointer;

  > span:nth-child(1) {
    color: ${(props) => (props.theme.name === 'light' ? 'grey' : 'white')};
    font-size: 12px;

    @media (max-width: 768px) {
      color: white;
    }
  }

  > span:nth-child(2) {
    margin-left: 10px;
    color: ${(props) => (props.theme.name === 'light' ? 'grey' : 'white')};
    font-size: 12px;
    font-weight: 800;

    @media (max-width: 768px) {
      color: white;
    }
  }

  @media (max-width: 768px) {
    margin-top: 100px;
  }
`;

const PasswordContainer = styled.div`
  position: relative;
  > input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    box-sizing: border-box;
    border: 1px solid lightgray;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 300;

    &::placeholder {
      font-size: 14px;
      color: lightgrey;
      font-style: italic;
      font-weight: 300;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12-18 */
      font-size: 14px;
      color: lightgrey;
      font-style: italic;
      font-weight: 300;
    }
  }

  > .MuiSvgIcon-root {
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${(props) => (props.showpassword ? 'grey' : 'lightgrey')};
    cursor: pointer;
  }
`;

const ResetPassword = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
  cursor: pointer;

  > span {
    font-size: 12px;
    text-decoration: underline;
    color: ${(props) => (props.theme.name === 'light' ? 'dimgrey' : 'lightgrey')};

    @media (max-width: 768px) {
      color: white;
    }
  }

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  > span {
    color: red;
    font-size: 12px;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;
