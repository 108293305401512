import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { firestoreDate } from '../../helpers/firestoreDate';
import LoadingScreen from '../generales/LoadingScreen';
import Countdown from '../generales/CuentaRegresiva';
import PageLoadingError from '../generales/PageLoadingError';
import { orderBy } from '../../helpers/orderBy';
import LicitacionesDetailMenuAGantt from './Licitaciones_detail_menuA_Gantt';
import { convertTZ } from '../../helpers/convertTZ';
import { differenceDays } from '../../helpers/differenceDays';

export default function LicitacionesDetailMenuA({ licitacion, loading, error, stage, dates }) {
  const intl = useIntl();

  const categoriasOrdered = [];
  [...(licitacion?.secciones || []), { label: 'Sin Sección' }].forEach((x) => {
    if (!x.id) {
      categoriasOrdered.push({
        sectionName: x.label,
        data: (licitacion?.categorias || []).filter((y) => !y.section || y.section === ''),
      });
    } else {
      categoriasOrdered.push({
        sectionName: x.label,
        data: (licitacion.categorias || []).filter((y) => y.section === x.id),
      });
    }
  });

  const ganttData = [];
  [
    {
      id: undefined,
      value: 1,
      description: '',
    },
    ...(licitacion?.stages || []),
  ].forEach((x, index) => {
    // etapa
    ganttData.push({
      taskId: x.value,
      taskName: '',
      stageName: `${intl.formatMessage({ id: 'app.stage' })} ${x.value}`,
      stageNameL: '',
      status: { backgroundColor: 'rgba(0,0,0,0.1)', color: 'black', label: '' },
    });
    // Visita a Terreno
    if (licitacion?.visitaTerrenoStart && licitacion?.visitaTerrenoEnd) {
      const duration = differenceDays(
        new Date(licitacion?.visitaTerrenoEnd._seconds * 1000),
        new Date(licitacion?.visitaTerrenoStart._seconds * 1000)
      );
      ganttData.push({
        taskId: `${x.value}.${index}.1`,
        taskName: duration < 10 ? 'Visita a Terreno' : '',
        stageName: duration < 10 ? '' : 'Visita a Terreno',
        stageNameL: '',
        startDate: convertTZ(
          new Date(licitacion?.visitaTerrenoStart._seconds * 1000),
          'America/Santiago'
        ),
        endDate: convertTZ(
          new Date(licitacion?.visitaTerrenoEnd._seconds * 1000),
          'America/Santiago'
        ),
        parentId: x.value,
      });
    }
    // Entrega de Antecedentes
    if (licitacion?.entregaAntecedentesStart && licitacion?.entregaAntecedentesEnd) {
      const duration = differenceDays(
        new Date(licitacion?.entregaAntecedentesEnd._seconds * 1000),
        new Date(licitacion?.entregaAntecedentesStart._seconds * 1000)
      );
      ganttData.push({
        taskId: `${x.value}.${index}.2`,
        taskName: duration < 10 ? 'Entrega de Antecedentes' : '',
        stageName: duration < 10 ? '' : 'Entrega de Antecedentes',
        stageNameL: '',
        startDate: convertTZ(
          new Date(licitacion?.entregaAntecedentesStart._seconds * 1000),
          'America/Santiago'
        ),
        endDate: convertTZ(
          new Date(licitacion?.entregaAntecedentesEnd._seconds * 1000),
          'America/Santiago'
        ),
        parentId: x.value,
      });
    }
    //Estudio de Propuesta
    if (licitacion?.estudioStart && licitacion?.estudioEnd) {
      const duration = differenceDays(
        new Date(licitacion?.estudioEnd._seconds * 1000),
        new Date(licitacion?.estudioStart._seconds * 1000)
      );
      ganttData.push({
        taskId: `${x.value}.${index}.3`,
        taskName: duration < 10 ? 'Estudio de Propuesta' : '',
        stageName: duration < 10 ? '' : 'Estudio de Propuesta',
        stageNameL: '',
        startDate: convertTZ(
          new Date(licitacion?.estudioStart._seconds * 1000),
          'America/Santiago'
        ),
        endDate: convertTZ(new Date(licitacion?.estudioEnd._seconds * 1000), 'America/Santiago'),
        parentId: x.value,
      });
    }
    //Consultas
    if (licitacion?.questionStart && licitacion?.questionEnd) {
      const duration = differenceDays(
        new Date(licitacion?.questionEnd._seconds * 1000),
        new Date(licitacion?.questionStart._seconds * 1000)
      );
      ganttData.push({
        taskId: `${x.value}.${index}.4`,
        taskName: duration < 10 ? 'Consultas' : '',
        stageName: duration < 10 ? '' : 'Consultas',
        stageNameL: '',
        startDate: convertTZ(
          new Date(licitacion?.questionStart._seconds * 1000),
          'America/Santiago'
        ),
        endDate: convertTZ(new Date(licitacion?.questionEnd._seconds * 1000), 'America/Santiago'),
        parentId: x.value,
      });
    }
    // Respuestas
    if (licitacion?.answerStart && licitacion?.answerEnd) {
      const duration = differenceDays(
        new Date(licitacion?.answerEnd._seconds * 1000),
        new Date(licitacion?.answerStart._seconds * 1000)
      );
      ganttData.push({
        taskId: `${x.value}.${index}.5`,
        taskName: duration < 10 ? 'Respuestas' : '',
        stageName: duration < 10 ? '' : 'Respuestas',
        stageNameL: '',
        startDate: convertTZ(new Date(licitacion?.answerStart._seconds * 1000), 'America/Santiago'),
        endDate: convertTZ(new Date(licitacion?.answerEnd._seconds * 1000), 'America/Santiago'),
        parentId: x.value,
      });
    }
    // Recepción de Ofertas
    if (licitacion?.offerStart && licitacion?.offerEnd) {
      const duration = differenceDays(
        new Date(licitacion?.offerEnd._seconds * 1000),
        new Date(licitacion?.offerStart._seconds * 1000)
      );
      ganttData.push({
        taskId: `${x.value}.${index}.6`,
        taskName: '',
        stageName: duration < 10 ? '' : 'Recepción de Ofertas',
        stageNameL: duration < 10 ? 'Recepción de Ofertas' : '',
        startDate: convertTZ(new Date(licitacion?.offerStart._seconds * 1000), 'America/Santiago'),
        endDate: convertTZ(new Date(licitacion?.offerEnd._seconds * 1000), 'America/Santiago'),
        parentId: x.value,
      });
    }
  });

  return loading ? (
    <ContentContainer>
      <LoadingScreen text={intl.formatMessage({ id: 'app.loadingdata' })} />
    </ContentContainer>
  ) : error ? (
    <ContentContainer>
      <PageLoadingError />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <InputContainer complete={true}>
        <DataContainer italic={true}>
          <span>
            <FormattedMessage id="app.licitaciones.configTitleA" defaultMessage={''} />
          </span>
          <InformationContainer>
            <div>
              <span>
                <FormattedMessage id="app.title" defaultMessage={''} />
              </span>
              <div>
                <span>{licitacion.name}</span>
              </div>
            </div>
            <div>
              <span>
                <FormattedMessage id="app.description" defaultMessage={''} />
              </span>
              <div>
                <span>{licitacion.description || '--'}</span>
              </div>
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configInfA" defaultMessage={''} />
              </span>
              <div>
                <span>{licitacion.instructions || '--'}</span>
              </div>
            </div>
            <div>
              <span>
                <FormattedMessage id="app.contact" defaultMessage={''} />
              </span>
              <div>
                <span>{licitacion.contactName}</span>
              </div>
            </div>
            <div>
              <span></span>
              <div>
                <span>{licitacion.contactEmail}</span>
              </div>
            </div>
          </InformationContainer>
        </DataContainer>
      </InputContainer>
      <InputContainer complete={true}>
        <DataContainer italic={true}>
          <span>
            <FormattedMessage id="app.stages" defaultMessage={''} />
          </span>
          <InformationContainer>
            <div>
              <span>
                <FormattedMessage id="app.stage" defaultMessage={''} /> 1
              </span>
              <div>
                <span>
                  <FormattedMessage id="app.licitaciones.info" defaultMessage={''} />
                </span>
                {!licitacion?.stageActive && (
                  <div>
                    <span>
                      <FormattedMessage id="app.licitaciones.infoB" />
                    </span>
                  </div>
                )}
              </div>
            </div>
            {orderBy([...(licitacion?.stages || [])], 'value').map((x) => (
              <div key={x.value}>
                <span>
                  <FormattedMessage id="app.stage" defaultMessage={''} /> {x.value}
                </span>
                <div>
                  <span>{x.description}</span>
                  {licitacion?.stageActive === x.id && (
                    <div>
                      <span>
                        <FormattedMessage id="app.licitaciones.infoB" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </InformationContainer>
        </DataContainer>
      </InputContainer>
      <GanttContainer className={'gantt-container'}>
        <LicitacionesDetailMenuAGantt ganttData={ganttData} />
      </GanttContainer>
      <InputContainer complete={true}>
        <DataContainer italic={true}>
          <span>
            <FormattedMessage id="app.licitaciones.configTitleB" defaultMessage={''} />
          </span>
          <DateTimeContainer>
            <div>
              <span>
                <FormattedMessage id="app.event" />
              </span>
              <span>
                <FormattedMessage id="app.start" />
              </span>
              <span>
                <FormattedMessage id="app.finish" />
              </span>
              <span>
                <FormattedMessage id="app.ends" />
              </span>
            </div>

            {licitacion.visitaTerrenoStart && licitacion.visitaTerrenoEnd && (
              <div>
                <span>
                  <FormattedMessage id="app.licitaciones.configDateTimeI" />
                </span>
                <span>
                  {licitacion.visitaTerrenoStart
                    ? firestoreDate(licitacion.visitaTerrenoStart, true, intl.locale)
                    : '--'}
                </span>
                <span>
                  {licitacion.visitaTerrenoEnd
                    ? firestoreDate(licitacion.visitaTerrenoEnd, true, intl.locale)
                    : '--'}
                </span>
                {licitacion.visitaTerrenoEnd ? (
                  <Countdown targetDate={licitacion.visitaTerrenoEnd} />
                ) : (
                  <span>--</span>
                )}
              </div>
            )}
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeJ" />
              </span>
              <span>
                {licitacion.entregaAntecedentesStart
                  ? firestoreDate(licitacion.entregaAntecedentesStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {licitacion.entregaAntecedentesEnd
                  ? firestoreDate(licitacion.entregaAntecedentesEnd, true, intl.locale)
                  : '--'}
              </span>
              {licitacion.entregaAntecedentesEnd ? (
                <Countdown targetDate={licitacion.entregaAntecedentesEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeK" />
              </span>
              <span>
                {licitacion.estudioStart
                  ? firestoreDate(licitacion.estudioStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {licitacion.estudioEnd
                  ? firestoreDate(licitacion.estudioEnd, true, intl.locale)
                  : '--'}
              </span>
              {licitacion.estudioEnd ? (
                <Countdown targetDate={licitacion.estudioEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeL" />
              </span>
              <span>
                {licitacion.questionStart
                  ? firestoreDate(licitacion.questionStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {licitacion.questionEnd
                  ? firestoreDate(licitacion.questionEnd, true, intl.locale)
                  : '--'}
              </span>
              {licitacion.questionEnd ? (
                <Countdown targetDate={licitacion.questionEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeM" />
              </span>
              <span>
                {licitacion.answerStart
                  ? firestoreDate(licitacion.answerStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {licitacion.answerEnd
                  ? firestoreDate(licitacion.answerEnd, true, intl.locale)
                  : '--'}
              </span>
              {licitacion.answerEnd ? (
                <Countdown targetDate={licitacion.answerEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeH" />
              </span>
              <span>
                {licitacion.offerStart
                  ? firestoreDate(licitacion.offerStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {licitacion.offerEnd ? firestoreDate(licitacion.offerEnd, true, intl.locale) : '--'}
              </span>
              {licitacion.offerEnd ? (
                <Countdown targetDate={licitacion.offerEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeN" />
              </span>
              <span>
                {licitacion.adjudicacionStartNull
                  ? firestoreDate(licitacion.adjudicacionStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {licitacion.adjudicacionEndNull
                  ? firestoreDate(licitacion.adjudicacionEnd, true, intl.locale)
                  : '--'}
              </span>
              {licitacion.adjudicacionEndNull ? (
                <Countdown targetDate={licitacion.adjudicacionEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
          </DateTimeContainer>
        </DataContainer>
      </InputContainer>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgGray100};
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  > span:nth-child(1) {
    font-size: 14px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.cincoColor};
    font-weight: 500;
  }
  > span:nth-child(2) {
    font-size: 14px;
    font-weight: 300;
    font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  }
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-column-gap: 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
    > span {
      font-size: 14px;
      font-weight: 300;
    }
    > div {
      display: flex;
      > span {
        font-size: 14px;
        font-weight: 300;
      }
      > div {
        display: flex;
        align-items: center;
        background-color: limegreen;
        border-radius: 4px;
        padding: 0 5px;
        margin-left: 15px;

        > span {
          color: white;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }

  > div:nth-child(4) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  > div:nth-child(5) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  > div:nth-child(8) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    border-bottom: 1px solid whitesmoke;
    > span {
      font-size: 14px;
      font-weight: 300;
    }
  }

  > div:nth-child(1) {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 5px;
    padding-bottom: 5px;

    > span {
      font-size: 13px;
      font-weight: 400;
    }
  }
`;

const GanttContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  min-height: 390px;
`;
