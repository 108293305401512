import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectLicitaciones,
  selectLicitacionesError,
  selectLicitacionesLoading,
} from '../../features/licitaciones/licitacionesSlice';
import PageContent from '../generales/PageContent';
import LoadingScreen from '../generales/LoadingScreen';
import PageLoadingError from '../generales/PageLoadingError';
import { FormattedMessage } from 'react-intl';
import { selectUser } from '../../features/user/userSlice';
import Countdown from '../generales/CuentaRegresiva';

export default function Licitaciones() {
  const user = useSelector(selectUser);
  const licitaciones = useSelector(selectLicitaciones);
  const loading = useSelector(selectLicitacionesLoading);
  const error = useSelector(selectLicitacionesError);
  const navigate = useNavigate();

  return (
    <PageContent>
      <ContentContainer>
        <Title>
          <span>Bienvenido/a {user.name.toUpperCase()}!,</span>
        </Title>
        <SubTitle>
          <span>
            A continuacón toda la información respecto a las licitaciones que has sido invitado a
            participar
          </span>
        </SubTitle>
        {loading ? (
          <LoadingScreen text={'Cargando Licitaciones...'} />
        ) : error ? (
          <PageLoadingError />
        ) : (
          <SectionContainer>
            <HeaderContainer>
              <span>
                <FormattedMessage id="app.company" />
              </span>
              <span>
                <FormattedMessage id="app.name" />
              </span>
              <span>
                <FormattedMessage id="app.licitaciones.menuC" />
              </span>
              <span>
                <FormattedMessage id="app.licitaciones.menuD" />
              </span>
              <span>
                <FormattedMessage id="app.licitaciones.gridA" />
              </span>
            </HeaderContainer>
            {licitaciones.length === 0 ? (
              <EmptyContainer>
                <span>
                  <FormattedMessage id="app.empty" />
                </span>
              </EmptyContainer>
            ) : (
              licitaciones.map((x) => (
                <RowContainer
                  key={x.idLicitacion}
                  onClick={() => navigate(`/licitaciones/${x.idLicitacion}/detail`)}
                >
                  <span>{x.clienteName}</span>
                  <span>{x.name}</span>
                  <Countdown targetDate={x.questionEnd} />
                  <Countdown targetDate={x.offerEnd} />
                  <Countdown targetDate={x.bidEnd} />
                </RowContainer>
              ))
            )}
            <FooterContainer>
              <span>
                Total {licitaciones.length} <FormattedMessage id={'app.licitaciones.pageTitle'} />
              </span>
            </FooterContainer>
          </SectionContainer>
        )}
      </ContentContainer>
    </PageContent>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  max-width: 1024px;
  padding: 25px;
`;

const Title = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 500;
`;

const SubTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 300;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  position: relative;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  overflow-x: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 200px 150px 150px 150px;
  grid-column-gap: 15px;
  border-bottom: 1px solid lightgrey;
  /* background-color: whitesmoke; */
  padding: 5px 0;

  > span {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
  }
  > span:nth-child(1) {
    margin-left: 10px;
  }
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 200px 150px 150px 150px;
  grid-column-gap: 15px;
  border-bottom: 1px solid whitesmoke;
  padding: 5px 0;
  cursor: pointer;

  > span {
    font-size: 14px;
    font-weight: 300;
  }
  > span:nth-child(1) {
    margin-left: 10px;
  }

  &:hover {
    background-color: whitesmoke;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  padding: 15px 10px;

  > span {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  padding: 25px 10px 0 10px;

  > span {
    font-size: 12px;
    font-weight: 300;
    text-transform: lowercase;
  }
`;
