import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  GanttComponent,
  DayMarkers,
  Inject,
  Edit,
  Selection,
  Toolbar,
  ColumnsDirective,
  ColumnDirective,
  ColumnMenu,
  EventMarkersDirective,
  EventMarkerDirective,
  Filter,
  Reorder,
  Resize,
  Sort,
  RowDD,
  CriticalPath,
} from '@syncfusion/ej2-react-gantt';
import './Licitaciones_detail_menuA_Gantt.css';
import { useIntl } from 'react-intl';

export default function LicitacionesDetailMenuAGantt({ ganttData = [] }) {
  const intl = useIntl();
  const ref = useRef();

  const taskFields = {
    id: 'taskId',
    name: 'taskName',
    startDate: 'startDate',
    endDate: 'endDate',
    duration: 'duration',
    progress: 'progress',
    parentID: 'parentId',
  };
  const toolbar = ['ZoomIn', 'ZoomOut', 'ZoomToFit'];
  const labelSettings = {
    // taskLabel: 'taskName',
    // rightLabel: '${duration} días',
    rightLabel: 'taskName',
    leftLabel: '${taskData.stageNameL}',
    // rightLabel: '${taskData.todayProgress}% ELB / ${taskData.todayReScheduleProgress}% ERE',
    // rightLabel: 'Task Name: ${taskData.TaskName}',
    taskLabel: '${taskData.stageName}',
  };

  const eventMarkerToday = new Date();

  const queryTaskbarInfo = (args) => {
    if (args.data) {
      args.taskbarBgColor = args?.data?.taskData?.status?.backgroundColor;
      args.taskbarBorderColor = args?.data?.taskData?.status?.backgroundColor;
      args.progressBarBgColor =
        args?.data?.taskData?.progress === 100
          ? args?.data?.taskData?.status?.backgroundColor
          : 'rgba(0, 0, 0, 0.2)';
      args.baselineColor = 'lightgrey';
      args.taskLabelColor = args?.data?.taskData?.status?.color;
      // console.log(args);
      // if (args?.data?.taskData?.taskId === 'Edificio') {
      //   args.taskbarHeight = 10;
      // }
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.fitToProject();
    }
  }, []);

  // console.log({ intl: intl.locale });

  return (
    <GanttContainer>
      <GanttComponent
        height={'100%'}
        width={'100%'}
        id="Editing"
        dataSource={ganttData}
        dateFormat={intl.locale === 'es' ? 'dd/MM/yyyy' : 'MM/dd/yyy'}
        treeColumnIndex={1}
        allowFiltering={false}
        allowSorting={true}
        allowSelection={false}
        allowExcelExport={true}
        allowPdfExport={false}
        allowRowDragAndDrop={false}
        allowResizing={true}
        showColumnMenu={true}
        highlightWeekends={true}
        allowUnscheduledTasks={true}
        taskFields={taskFields}
        timelineSettings={{ weekStartDay: 1 }}
        labelSettings={labelSettings}
        splitterSettings={{ view: 'Chart' }}
        gridLines={'Horizontal'}
        toolbar={toolbar}
        toolbarClick={() => {}}
        ref={(gantt) => (ref.current = gantt)}
        dataBound={ref.current ? () => ref.current.fitToProject() : null}
        queryTaskbarInfo={queryTaskbarInfo}
        includeWeekend={true}
        enableCriticalPath={false}
        rowHeight={40}
        taskbarHeight={22}
        dayWorkingTime={[{ from: 0, to: 24 }]}
        renderBaseline={false}
        onTaskbarClick={() => {}}
        collapseAllParentTasks={false}
        sortSettings={{
          columns: [{ field: 'startDate', direction: 'Ascending' }],
        }}
      >
        <ColumnsDirective>
          <ColumnDirective field="taskId" visible={false} />
          <ColumnDirective
            field="taskName"
            headerText={intl.formatMessage({ id: 'app.gantt.activity' })}
            clipMode="EllipsisWithTooltip"
            width={300}
            visible={true}
          />
          <ColumnDirective
            field="duration"
            headerText={intl.formatMessage({ id: 'app.gantt.duration' })}
            width={140}
            visible={true}
          />
          <ColumnDirective
            field="startDate"
            headerText={intl.formatMessage({ id: 'app.gantt.start' })}
            visible={false}
          />
          <ColumnDirective
            field="endDate"
            headerText={intl.formatMessage({ id: 'app.gantt.finish' })}
            visible={false}
          />
          <ColumnDirective
            field="progress"
            headerText={intl.formatMessage({ id: 'app.gantt.progress' })}
            visible={false}
          />
          <ColumnDirective
            field="predecessor"
            headerText={intl.formatMessage({ id: 'app.gantt.predecessor' })}
            visible={false}
          />
        </ColumnsDirective>
        <EventMarkersDirective>
          <EventMarkerDirective
            day={eventMarkerToday}
            label={intl.formatMessage({ id: 'app.gantt.today' })}
          ></EventMarkerDirective>
        </EventMarkersDirective>
        <Inject
          services={[
            Edit,
            Selection,
            Toolbar,
            DayMarkers,
            Filter,
            Reorder,
            Resize,
            ColumnMenu,
            Sort,
            RowDD,
            CriticalPath,
          ]}
        />
      </GanttComponent>
    </GanttContainer>
  );
}

const GanttContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  box-sizing: border-box;
  border-radius: 8px;

  > .e-control {
    border-radius: 8px;
  }

  > .e-gantt {
    border-radius: 8px;
  }

  > .e-gantt .e-spinner-pane {
    border-radius: 8px;
  }

  > .e-gantt .e-gantt-toolbar {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-color: transparent;
  }

  > .e-gantt .e-gantt-toolbar .e-toolbar-items {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  > .e-gantt .e-gantt-splitter .e-gantt-tree-grid-pane {
    border-bottom-left-radius: 8px;

    > .e-gantt-tree-grid .e-gridcontent {
      border-bottom-left-radius: 8px;
    }
  }

  > .e-gantt .e-gantt-splitter .e-gantt-chart-pane {
    border-bottom-right-radius: 8px;
  }
`;
