import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Timestamp, addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import 'yet-another-react-lightbox/styles.css';

import { db } from '../../firebase/firebase';
import { selectUser } from '../../features/user/userSlice';
import ModalContainer from '../generales/ModalContainer';
import LoadingScreen from '../generales/LoadingScreen';
import { FormInputTextMultine } from '../forms/FormInputTextMultine';

export default function LicitacionesDetailMenuDCommentModal({
  open,
  onClose,
  licitacion,
  categoria,
  isStageActive,
  periodoEntregaOfertas,
}) {
  const user = useSelector(selectUser);
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const defaultValues = {
    value: categoria?.comment?.value || '',
  };

  const methods = useForm({ defaultValues: defaultValues, mode: 'onChange' });
  const { handleSubmit, reset, control, setValue, formState, watch } = methods;

  const submitHandler = async (data) => {
    setLoading(true);
    try {
      if (categoria.comment) {
        await updateDoc(doc(db, `licitaciones_files_comments/${categoria.comment.idComment}`), {
          value: data.value,
          updatedOn: Timestamp.fromDate(new Date()),
        });
      } else {
        await addDoc(collection(db, 'licitaciones_files_comments'), {
          value: data.value,
          idCategoria: categoria.idCategoria,
          idLicitacion: licitacion.idLicitacion,
          idProject: licitacion.idProject,
          userId: user.userId,
          createdAt: Timestamp.fromDate(new Date()),
        });
      }
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
    }
  };

  const isValid = () => {
    if (!formState.isValid || loading || !isStageActive || !periodoEntregaOfertas) return false;
    return true;
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={`${intl.formatMessage({
        id: 'app.add',
        defaultMessage: 'Add',
      })} ${intl.formatMessage({ id: 'app.comments', defaultMessage: 'Comment' })}`}
      maxWidth={'600px'}
      buttonsArray={[
        {
          name: intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' }),
          isValid: isValid(),
          onClick: isValid() ? handleSubmit(submitHandler) : null,
        },
      ]}
    >
      {loading ? (
        <LoadingScreen />
      ) : (
        <ContentContainer>
          <InputContainer>
            <Title>{intl.formatMessage({ id: 'app.document' })}</Title>
            <Description>{categoria.label}</Description>
            <Spacer height={'10px'} />
            <Title>{intl.formatMessage({ id: 'app.description' })}</Title>
            {(categoria.description || '').length > 0 ? (
              <Description>{categoria.description}</Description>
            ) : (
              <NoDescription>Sin descripción</NoDescription>
            )}
          </InputContainer>
          <InputContainer>
            <Title>Comentario o indicación</Title>
            <Spacer height={'10px'} />
            <FormInputTextMultine
              name={'value'}
              control={control}
              label={intl.formatMessage({ id: 'app.writehere' })}
              required={false}
              rows={5}
              disabled={loading || !isStageActive || !periodoEntregaOfertas}
            />
          </InputContainer>
        </ContentContainer>
      )}
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: whitesmoke;
  border-radius: 8px;
  margin-bottom: 15px;
`;

const Title = styled.span`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
`;

const NoDescription = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  font-style: italic;
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;
